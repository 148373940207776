import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Header, Form as PlanDetailForm } from './components';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchPlanDetail } from '../../features/planDetails/planDetailsSlice';

const PlanSummaryFieldUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const planDetailsStatus = useSelector((state) => state.planDetails.status);
  const { planDetailId, field } = useParams();
  const planDetail = useSelector((state) => {
    if (state.planDetails.data.entities.planDetails[planDetailId]) {
      return state.planDetails.data.entities.planDetails[planDetailId];
    }
  });

  useEffect(() => {
    if (
      planDetailsStatus === 'idle' ||
      (planDetailsStatus === 'succeeded' && !planDetail)
    ) {
      navigate('/dashboard/plan-details');
    }
  }, [planDetailsStatus]);

  return (
    <>
      {planDetail && (
        <>
          <div className="p-3">
            <Header plan={planDetail} field={field} />
          </div>
          <div className="p-3">
            <PlanDetailForm field={field} planDetail={planDetail} />
          </div>
        </>
      )}
    </>
  );
};

export default PlanSummaryFieldUpdate;
