import axios from 'axios';
import { history } from '../history';
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json'
  }
});

api.interceptors.request.use(function (request) {
  const user =
    localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

  if (user?.token) {
    request.headers['Authorization'] = `Bearer ${user.token}`;
  }

  return request;
});

api.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        if (error.response.status === 401) {
          history.replace('/unauthorized');
        } else if (error.response.status === 500) {
          toast.error(
            'There is a critical internal server error (Error: 500)',
            {
              position: 'top-center',
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined
            }
          );
        } else {
          toast.error(error.message);
        }
      } else if (error.request) {
        // The request was made but no response was received
        toast.error(error.message);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        toast.error('Error: ' + error.message);
      }

      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
