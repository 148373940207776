import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Details } from './components';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchPlanDetail } from 'features/planDetails/planDetailsSlice';

const PlanSummaryView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const planDetailsStatus = useSelector((state) => state.planDetails.status);
  const { planDetailId } = useParams();
  const planDetail = useSelector(
    (state) => state.planDetails.data.entities.planDetails[planDetailId]
  );

  useEffect(() => {
    if (planDetailsStatus === 'idle') {
      navigate('/dashboard/plan-details');
    }
  }, [planDetailsStatus]);

  useEffect(() => {
    if (planDetail && planDetail.summary === undefined) {
      //planDetail.summary could be empty string, important to check with only undefined
      // we have a bug in here; infinite loop;
      dispatch(fetchPlanDetail(planDetailId));
    }
  }, []);

  return (
    <>
      {planDetail && (
        <>
          <Header plan={planDetail} />
          <Details
            planDetails={planDetail}
            /*onClickDelete={deletePlanDetail}*/
          />
        </>
      )}
    </>
  );
};

export default PlanSummaryView;
