import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { editPlanDetailsField } from 'features/planDetails/planDetailsSlice';
import { useNavigate } from 'react-router-dom';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const planDetailSchema = Yup.object().shape({
  company: Yup.string().required('Required'),
  summary: Yup.string().nullable(),
  requirements: Yup.string().nullable(),
  claim: Yup.string().nullable(),
  refund: Yup.string().nullable(),
  exclusions: Yup.string().nullable()
});

const UpdateForm = ({ field, planDetail }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(null);

  useState(() => {
    if (planDetail[field]) {
      const { contentBlocks, entityMap } = htmlToDraft(planDetail[field]);

      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlocks, entityMap)
        )
      );
    }
  }, [planDetail[field]]);

  return (
    <>
      <Formik
        initialValues={{
          field: field,
          content: '',
          ...planDetail
        }}
        onSubmit={(values, { setSubmitting }) => {
          // same shape as initial values
          dispatch(
            editPlanDetailsField({
              ...values,
              planDetailId: planDetail._id,
              content: draftToHtml(
                convertToRaw(editorState.getCurrentContent())
              )
            })
          )
            .unwrap()
            .then((planDetail) => {
              navigate(`/dashboard/plan-details/${planDetail._id}`);
            })
            .catch((error) => console.error(error))
            .finally(() => setSubmitting(false));
        }}
        validationSchema={planDetailSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="formGroup">
              <Editor
                editorClassName="plan-details-editor"
                editorState={editorState}
                onEditorStateChange={setEditorState}
                placeholder="The message goes here..."
                wrapperClassName="rich-editor"
              />
            </div>

            <Button
              color="primary"
              disableElevation
              disabled={isSubmitting}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? 'Updating' : 'Update'}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateForm;
